import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography, Fab} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


 const number=css`
   position: relative;
   
   font-weight: bold;
    `
     const numIcon=css`
    border-radius: 50%;
    width: 46px;
    height: 46px;
    padding: 8px;

    background: #fff;
    border: 2px solid #fff;
    color: #666;
    text-align: center;

    font: 14px Arial, sans-serif;
    `
    const buttonCss = css`
    @media(min-width: 360px ){
               margin-left: -7px;
            }
     @media(min-width: 468px ){
                margin-left: 10%;
            }
            @media(min-width: 960px ){
                margin-left: 40%;
            }
    
`

 
class AcercaDeCmp extends Component {    

    render() {
        const {            
            titleIcon='Conoce más acerca de Apoyo Financiero',
            txtTitle=<span><strong>MISIÓN</strong><br/>Creamos oportunidades y acceso a productos financieros ofreciendo un servicio excepcional.<hr/></span>,
            txtContent=<span><strong>VISIÓN</strong><br/>Construir comunidades mas fuertes mediante el acceso a productos financieros, incrementando las posibilidades de crecimiento profesional, apoyando a quienes más lo necesitan.<hr/></span>,
            txtTitleEmp='Nuestra Empresa',
            txtContentValors=<span><strong>VALORES</strong><br/><ul><li>Integridad.</li><li>Honestidad.</li><li>Trabajo en equipo.</li><li>Liderazgo.</li><li>Responsabilidad Social.</li></ul><hr/></span>,
            txtContentOne='Historia',
            txtContentTwo=<span>Se constituyó <strong>Apoyo Financiero</strong>, microfinanciera dedicada a otorgar préstamos sobre todo al mercado en el Área de la Bahía de San Francisco.</span>,
            txtHistory=<span>Financiera Independencia adquirió el 75% de las acciones de Apoyo Financiero con la finalidad de apoyar el crecimiento en sucursales buscando incrementar nuestra presencia en el mercado.<br/>Fue entonces que Apoyo Financiero comenzó a expandir su cobertura, abriendo la sucursal de Oakland.</span>,
            txtHistoryOne='Se abrieron nuestras puertas en San Jose, Concord y San Pablo.',
            txtHistoryTwo='Abrimos en South San Francisco, Hayward, Santa Rosa, Napa, San Jose-Blossom Hill, Tracy y Gilroy.',
            txtHistory2014='Abrimos en Salinas, Sacramento, Modesto, y Woodland.',
            txtHistory2015='Abrimos en Merced, San Rafael, San Bernardino, y Stockton.',
            txtHistory2016='Abrimos en Bakersfield, Fresno, Corona, Riverside, Santa Ana, y Fontana',
            txtHistoryHoy='Contamos con más de 25 sucursales y tenemos planes importantes de crecimiento para poder atender a más clientes y contribuir al crecimiento económico en California.',
            txtTitleImpact='Impacto social',
            txtContentImpact=<span>La meta de Apoyo Financiero es mejorar las vidas de nuestros clientes proporcionándoles acceso al crédito: mejorar su nivel de vida a través de la creación y adquisición de activos.<br/><br/>Nuestro objetivo es ser la mejor fuente de recursos para la comunidad; ofreciendo a nuestros clientes préstamos que se adapten a sus necesidades con las condiciones de pago y servicio que se merecen.</span>
        } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtContentValors}                        
                    </Typography>
                    <br/><br/>


                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='h3' variant="h4">
                            <span style={{color:"#698d21"}}>{txtContentOne}</span>                        
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2007</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>                        
                       <Typography component='subtitle' variant="subtitle" >
                             
                            {txtContentTwo}           
                             <br/>&nbsp;           
                         </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2011</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistory}                        
                         </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2012</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistoryOne}                        
                         </Typography>
                    </Grid>
                     <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2013</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistoryTwo}                        
                         </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2014</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistory2014}                        
                         </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2015</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistory2015}                        
                         </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>2016</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistory2016}                        
                         </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div css={buttonCss} >
                        <Fab color="secondary" aria-label="add">                            
                            <span css={numIcon}><span css={number}>HOY</span></span> 
                        </Fab>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtHistoryHoy}                        
                         </Typography>
                    </Grid>
                </Grid>                    
                    <br/>
                    <hr/>
                   <Grid item xs={12} sm={12}>
                        <Grid item xs={12}>
                      <Typography component='h3' variant="h4">
                            <span style={{color:"#698d21"}}> {txtTitleImpact} </span>                       
                         </Typography>
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                       <Typography component='subtitle' variant="subtitle" >
                            {txtContentImpact}                        
                         </Typography>
                    </Grid>
                    </Grid> 

                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

AcercaDeCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    txtContentValors: PropTypes.string,
    txtContentOne: PropTypes.string, 
    txtContentTwo: PropTypes.string, 
    txtHistory: PropTypes.string, 
    txtHistoryOne: PropTypes.string, 
    txtHistoryTwo: PropTypes.string, 
    txtHistory2014: PropTypes.string, 
    txtHistory2015: PropTypes.string, 
    txtHistory2016: PropTypes.string, 
    txtHistoryHoy: PropTypes.string, 
    txtTitleImpact: PropTypes.string, 
    txtContentImpact: PropTypes.string
}

export default withThemeProps(AcercaDeCmp, 'VDPNAcercaDe')






